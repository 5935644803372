import React, {useState, useEffect} from 'react';
import { Container, Col, InputGroup,  Button, Form } from 'react-bootstrap';
import ModalFin from './components/ModalFin';
import {Translations} from '../src/constants/i18n';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");

const Devis = ({translation}) => {

    const URL = 'https://www.useradventure.net/'
    const [Nom, setNom] = useState('');
    const [Entreprise, setEntreprise] = useState('');
    const [value1, setvalue1] = useState('');
    const [value2, setvalue2] = useState('');
    const [Prenom, setPrenom] = useState('');
    const [Mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [msg, setMsg] = useState('');
    const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
    const [disabled, setdisabled] = useState(false);
    const contact1 = 'maxime.aldegheri@useradventure.fr'
    const contact2 = 'maxime.aldegheri@healthsquad.fr'
    const [showModal, setshowModal] = useState(false);

    const option1 = [
      { value: '', label: 'Selectionner...' },
      { value: 1, label: 'Recrutement de participants' },
      { value: 2, label: 'Loi anti-cadeau et dispositif transparence' },
      { value: 3, label: 'Recrutement de participants et Loi anti-cadeau dispositif transparence' },
      { value: 4, label: `Suivi post-production des dispositifs médicaux` }
    ]

    const option2 = [
      { value: '', label: 'Selectionner...' },
      { value: 1, label: 'Par Mail' },
      { value: 2, label: 'Par téléphone' }
    ]

    const t = (value) => {
      return Translations[0][translation][value];
     }
  

    useEffect(()=>{
      window.scrollTo(0, 0)
     
      }, [])
    

    const ChangeNom = (event) => {
        setNom(event)
        if(event !== '' && Prenom !=='' && Mail !=='' && phone !=='' && msg !=='' && Entreprise !=='' && value1 !=='' && value2 !==''){
          setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      const ChangePrenom = (event) => {
        setPrenom(event)
        if(event !== '' && Nom !=='' && Mail !=='' && phone !=='' && msg !=='' && Entreprise !=='' && value1 !=='' && value2 !==''){
          setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

      const ChangeMail = (event) => {
        setMail(event)
        if(event !== '' && Nom !=='' && Prenom !=='' && phone !=='' && msg !=='' && Entreprise !=='' && value1 !=='' && value2 !==''){
          setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };


      const ChangeEntreprise = (event) => {
        setEntreprise(event)
        if(event !== '' && Nom !=='' && Prenom !=='' && phone !=='' && msg !=='' && Mail !=='' && value1 !=='' && value2 !==''){
          setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

      const ChangePhone = (event) => {
        setPhone(event)
        if(event !== '' && Nom !=='' && Prenom !=='' && Entreprise !=='' && msg !=='' && Mail !=='' && value1 !=='' && value2 !==''){
          setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

     

      const ChangeMsg = (event) => {
        setMsg(event)
        if(event !== '' && Nom !=='' && Prenom !=='' && Entreprise !=='' && phone !=='' && Mail !=='' && value1 !=='' && value2 !==''){
          setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };


      const changeHandler1 = (event) => {
        var data_filter = option1.filter( element => element.value == event)
        if(event !== '' && Nom !=='' && Prenom !=='' && Entreprise !=='' && phone !=='' && Mail !=='' && msg !=='' && value2 !==''){
          setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
        setvalue1(data_filter[0].label)
       console.log(data_filter[0].label)
      };
  
      const changeHandler2 = (event) => {
        var data_filter = option2.filter( element => element.value == event)
        if(event !== '' && Nom !=='' && Prenom !=='' && Entreprise !=='' && phone !=='' && Mail !=='' && value1 !=='' && msg !==''){
          setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
        setvalue2(data_filter[0].label)
       console.log(data_filter[0].label)
      };

      const run = async (message) => {
        const response = await mailchimp.messages.sendTemplate({
          template_name: "HSDevis",
          "template_content": [{}],
          message: message,
        });
        console.log(response);
        setNom('')
        setPrenom('')
        setMsg('')
        setMail('')
        setPhone('')
        setEntreprise('')
        setvalue1('')
        setvalue2('')
        setshowModal(true)
        setdisabledcolor('#DCDCDC')
        setdisabled(false)
      };
  

      const validCommand1 = () => {
        var position = Mail.indexOf("@");
        var reste = Mail.substr(-position);
        var posit = reste.indexOf(".");
        //alert(posit + position + Mail )
        if(position !== -1 && posit !== -1 ){
          if(disabled){
            const message = {
              from_name: "HealthSquadDevis",
              from_email: "contact@healthsquad.fr",
              subject: `Devis HealthSquad`,
              to: [
                {
                  email: contact1,
                  type: "to"
                },
                {
                  email: contact2,
                  type: "cc"
                }
              ],
              "merge_vars": [
                {
                    "rcpt": contact1,
                    "vars": [
                      {
                        "name": "EMAILPRO",
                        "content": Mail
                      },
                      {
                        "name": "TYPE",
                        "content": "Devis"
                      },
                      {
                        "name": "MOD",
                        "content": msg
                      },
                      {
                        "name": "NOM",
                        "content": Nom
                      },
                      {
                        "name": "PRENOM",
                        "content": Prenom
                      },
                      {
                        "name": "PHONE",
                        "content": phone
                      },
                      {
                        "name": "ENTREPRISE",
                        "content": Entreprise
                      },
                      {
                        "name": "VALUE1",
                        "content": value1
                      },
                      {
                        "name": "VALUE2",
                        "content": value2
                      },
                    ]
                },
                {
                  "rcpt": contact2,
                  "vars": [
                    {
                      "name": "EMAILPRO",
                      "content": Mail
                    },
                    {
                      "name": "TYPE",
                      "content": "Devis"
                    },
                    {
                      "name": "MOD",
                      "content": msg
                    },
                    {
                      "name": "NOM",
                      "content": Nom
                    },
                    {
                      "name": "PRENOM",
                      "content": Prenom
                    },
                    {
                      "name": "PHONE",
                      "content": phone
                    },
                    {
                      "name": "ENTREPRISE",
                      "content": Entreprise
                    },
                    {
                      "name": "VALUE1",
                      "content": value1
                    },
                    {
                      "name": "VALUE2",
                      "content": value2
                    },
                  ]
              }
            ]
            }
          run(message) 
            }else{
              alert('Veuillez remplir tous les champs, Merci!')
            }
            }else{
          alert('Email non valide')
        }
      }

 
    return (
        <div>
        <Container fluid style={{background: '#f0f0f8', paddingBottom : 120 ,paddingTop:200}}>

        <Col lg={{  span:6, offset:3}} style={{paddingInline:30, paddingBlock:0}} >

                <Col className="text-lg-center text-center gradient-text2"  style={{color:'black', fontSize:56,fontWeight: '600' ,marginBottom:10,lineHeight:1.2}}>{t("devis0")}</Col>
                <Col className="text-center" style={{marginTop:10,marginBottom:40,fontSize: 17,color: '#60697b',paddingInline:0, fontWeight:'400', fontFamily:'Space Grotesk, sans-serif'}}>{t("devis1")}</Col>
        
                            <div className="form-floating mb-3">
                                <input type="Nom" value={Nom} onChange={e =>ChangeNom(e.target.value)} style={{borderRadius:10}} className="form-control" id="floatingInput" placeholder="name@example.com"/>
                                <label>{t("devis2")} *</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="Nom" value={Prenom} onChange={e =>ChangePrenom(e.target.value)} style={{borderRadius:10}} className="form-control" id="floatingInput" placeholder="name@example.com"/>
                                <label>{t("devis3")} *</label>
                            </div>
                            
                           
                            <div className="form-floating mb-3">
                                <input type="Prénom" value={Mail} onChange={e =>ChangeMail(e.target.value)} style={{borderRadius:10}} className="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label>{t("devis4")} *</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="Prénom" value={phone} onChange={e =>ChangePhone(e.target.value)} style={{borderRadius:10}} className="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label>{t("devis5")} *</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="Nom" value={Entreprise} onChange={e =>ChangeEntreprise(e.target.value)} style={{borderRadius:10}} className="form-control" id="floatingInput" placeholder="name@example.com"/>
                                <label>{t("devis6")} *</label>
                            </div>

                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1" style={{borderTopLeftRadius:10, borderBottomLeftRadius:10}}>{t("devis7")} *</InputGroup.Text>
                                <Form.Select  onChange={e =>changeHandler1(e.target.value)} value={value1} style={{height:60,borderTopRightRadius:10, borderBottomRightRadius:10, fontSize:'1rem'}} size="lg" aria-label="Username" placeholder="Username" aria-describedby="basic-addon1">
                                {option1.map( (x) => 
                                <option key={x.value} value={x.value} label={x.label}>{x.label}</option> )
                                }
                            </Form.Select>
    
                                </InputGroup>
                           
                            <div className="form-floating mb-3">
                                <textarea  rows="5" type="Prénom" value={msg} onChange={e =>ChangeMsg(e.target.value)} style={{borderRadius:10, height:150}} className="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label>{t("devis8")} *</label>
                            </div>  
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1" style={{borderTopLeftRadius:10, borderBottomLeftRadius:10}}>{t("devis9")} *</InputGroup.Text>
                                <Form.Select  onChange={e =>changeHandler2(e.target.value)} value={value2} style={{height:60,borderTopRightRadius:10, borderBottomRightRadius:10, fontSize:'1rem'}} size="lg" aria-label="Username" placeholder="Username" aria-describedby="basic-addon1">
                                {option2.map( (x) => 
                                <option key={x.value} value={x.value} label={x.label}>{x.label}</option> )
                                }
                            </Form.Select>
    
                                </InputGroup>
    <div className="d-grid gap-2">
    <Button  onClick={()=>validCommand1()} variant="outline-light"  style={{borderWidth:0, background: disabledcolor, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}>Envoyer la demande</Button>
    </div>
        </Col>
  
        <ModalFin show={showModal} Quit={() => {setshowModal(false)}} />
        </Container>
       
        </div>
    )
}

export default Devis
