import React, {useState, useMemo, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import { Container, Card, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button, Form, Alert } from 'react-bootstrap';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, useWindowDimensions} from 'react-native';
import juliette from './images/juliette.png';
import fabien from './images/fabien.jpg';

const Post = ({props}) => {


  const { height, width } = useWindowDimensions();
  const [startData, setStartData] = useState('');
  const [Data, setData] = useState('');
    const auteurHS = [
      {id:1, Nom : 'Fabien Lheureux', img: fabien},
      {id:2, Nom : 'Juliette Carlier', img: juliette}
    ];
 
    const URL = 'https://www.useradventure.net/'
  
    let { id } = useParams();
  
    useEffect(()=>{

      fetch('https://www.useradventure.net/siteHS_json_get_Titreblog.php', {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ID: id})
      })
      .then((response) => response.json())
      .then((response) => { 
        setData(response)
    console.log(response)
      })
      .catch((error) => alert(error))
     
      fetch('https://www.useradventure.net/siteHS_json_get_blog.php', {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ID: id})
      })
        .then((response) => response.json())
        .then((response) => { 
          setStartData(response)
          console.log('startData',response)
         })
        .catch((error) => console.log(error))
      
      }, [])

     
      const result = (ev) => { 
      let filteredUserNames = auteurHS.filter(word => word.id == ev);
      return filteredUserNames[0].img;
      }

      const result2 = (ev) => { 
        let filteredUserNames = auteurHS.filter(word => word.id == ev);
        return filteredUserNames[0].Nom;
        }
      
   

    return (
        
        <>
       
      <Container style={{paddingTop:180, paddingBottom:180, backgroundColor:'#f5f7fa'}} fluid>  
      <Col md={{ span: 6, offset: 3 }}>
{startData && startData.map((item) => {
  if(item.Type === "1"){
    return(
 <Col className={"my-4 fw-bold fs-" + item.Size}  style={{fontFamily:'Space Grotesk, sans-serif'}}>{item.Text.replaceAll("","'")}</Col>)
 }else if(item.Type === "2"){
  return(
    <Col className={"my-4 fs-" + item.Size} style={{fontFamily:'Space Grotesk, sans-serif', textAlign:'justify'}} dangerouslySetInnerHTML={{__html: item.Text.replaceAll("","'")}}></Col>)
    {/* <Col className={"my-4 fs-" + item.Size}  style={{fontFamily:'Space Grotesk, sans-serif', textAlign:'justify'}}>
    {item.Text.replaceAll("","'")}</Col> */}
 }else if(item.Type === "3"){
  return(
    <Link to={item.Action === '0' ? undefined : item.Action }>
    <Col >
   <div style={{ height: width < 768 ? width/2 : width/3.6,
   marginBlock:30,
      width: '100%',
      backgroundImage: `url(${item.Text})`,
      backgroundSize: '100% 100%' ,
      borderRadius:5,
      objectFit:'cover',
     
    }}>
     
    </div>
   </Col>
   </Link>)
 }else if(item.Type === "4"){
  return(
    <Col className={"my-4 fst-italic fs-" + item.Size}  style={{fontFamily:'Space Grotesk, sans-serif', textAlign:'justify'}}>{item.Text.replaceAll("","'")}</Col>)

 }else if(item.Type === "5"){
  return(
    <li style={{lineHeight:1.4, marginLeft:30}}>{item.Text.replaceAll("","'")}</li> 
  )
 }
 })}
 <div className="border" style={{  marginTop:60}}/> 
   {Data && Data.map((item) => (
        <Col  style={{ padding:40, borderRadius:10, marginBlock:0}}>
          <Row>
          <Col className="my-auto" xs="2">
              <Image src={result(item.Auteur)} style={{ height:70, borderRadius:35}} />
              </Col>
                <Col xs="9">
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{result2(item.Auteur)}</Col>
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'300', fontFamily:'Space Grotesk, sans-serif'}}>{item.DATE_CREATE}</Col>
                </Col>  
              </Row>
            </Col>  

       ) )}
       </Col>  

      
</Container>
     </>
    )
}

export default Post

