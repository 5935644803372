import React from 'react'
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { BsYoutube} from "react-icons/bs";
import logo from '../images/Logo_HealthSquad_blanc.png';
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import {Translations} from '../constants/i18n';

const isActive = ({ isActive }) => `link ${isActive ? 'active' : ''}`

const Footer = ({Active, translation}) => {

console.log('ActiveRef',Active)
  const color = "#FFFFFF";

  const t = (value) => {
    return Translations[0][translation][value];
   }
  
  

        return (  <Container style={{ backgroundImage: `linear-gradient(147deg, #319f8f, #3f52a9 52%, #a85b82)`,paddingTop:60}}  fluid>
         <Col  xs={{ span: 8, offset: 2 }}>
        <Row className="justify-content-md-center" style={{marginTop:0}}>
        <Col md="3"  >
          <Col style={{borderWidth:0, paddingBottom:60}}>
          <Col style={{fontWeight:'bold',borderWidth:0,fontSize: 20, backgroundColor:'transparent', color:color, marginLeft: -25, paddingBottom:20}}><Image src={logo} style={{height:55}} fluid alt="logo_HealthSquad"/></Col>
          <Col style={{borderWidth:0, backgroundColor:'transparent', color:color, paddingBottom:20}}>
              <NavLink className={isActive} style={{color:'white', textDecorationLine:'inherit'}} to='/inscription'>{t("footer0")}</NavLink>
            </Col>
            <Col style={{fontSize:30,display:'flex',borderWidth:0, backgroundColor:'transparent', color:color}}>
          <FaLinkedin onClick={() =>{window.open("https://www.linkedin.com/company/health-squad-france/?viewAsMember=true", '_blank')}} style={{cursor:'pointer',marginRight:20}}/>
          <FaTiktok onClick={() =>{window.open("https://www.tiktok.com/@healthsquadfrance/", '_blank')}} style={{cursor:'pointer',marginRight:20}} />
          <BsYoutube onClick={() =>{window.open("https://www.youtube.com/@HealthSquad-kh8sq", '_blank')}} style={{cursor:'pointer'}}/>
          </Col>
        </Col>
        </Col>
        <Col md="3" >
        <Col style={{ paddingBottom:60}}>
        <Col style={{fontWeight:'bold',fontSize: 20,borderWidth:0, backgroundColor:'transparent', color:color, lineHeight: 1.8}}>{t("footer3")}</Col>
        <Col style={{fontSize: 17,borderWidth:0, backgroundColor:'transparent', color:color}}><span>PARC EURASANTE<br/>
             260 rue Salvador Allende<br/>
             Bâtiment B<br/>
             59120 LOOS<br/>
             FRANCE</span></Col>
        <Col style={{fontSize: 17,borderWidth:0, backgroundColor:'transparent', color:color, lineHeight: 1.8}}><a style={{fontSize: 17,borderWidth:0, backgroundColor:'transparent', color:color}} href='mailto:contact@healthsquad.fr'>contact@healthsquad.fr</a></Col>
        <Col style={{fontSize: 17,borderWidth:0, backgroundColor:'transparent', color:color, lineHeight: 1.8}}>07 69 11 46 02</Col>
        </Col>
        </Col>
        <Col md="3"  >
        <Col style={{ paddingBottom:60}}>
        <Col style={{fontWeight:'bold',fontSize: 20,borderWidth:0, backgroundColor:'transparent', color:color, lineHeight: 1.8}}>Informations</Col>
        <Col style={{borderWidth:0, backgroundColor:'transparent', color:color, paddingBottom:20}}><NavLink className={isActive} style={{color:'white', textDecorationLine:'inherit', fontWeight:'400'}} to='/CGU'>{t("footer1")}</NavLink></Col>
        <Col style={{borderWidth:0, backgroundColor:'transparent', color:color, paddingBottom:20}}><NavLink className={isActive} style={{color:'white', textDecorationLine:'inherit', fontWeight:'400'}} to='/PPDP'>{t("footer2")}</NavLink></Col>
        </Col>
        </Col>
      </Row>
      </Col>
       
        </Container>
        )
    }


export default Footer
